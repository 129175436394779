// src/components/Footer.jsx
import React from "react";

const Footer = () => {
  return (
    <footer className="footer bg-gray-800 text-white py-4">
      <div className="container mx-auto text-center">
        <p>
          &copy; {new Date().getFullYear()} Osprey BP - Serving Boise, Meridian,
          Eagle, Nampa, and Surrounding Areas in Idaho.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
