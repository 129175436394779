// src/components/SEO.jsx
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, keywords, url, image }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />

      {/* Open Graph */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="OspreyBP" />
      <meta property="og:locale" content="en_US" />

      {/* Twitter Card */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image" content={image} />

      {/* Favicon */}
      <link
        rel="icon"
        type="image/png"
        href="https://www.ospreybp.com/favicon.png"
      />

      {/* Schema.org */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ProfessionalService",
          name: "OspreyBP Drone Services",
          serviceArea: {
            "@type": "Place",
            name: ["Boise", "Idaho"],
          },
          services: [
            "Drone Photography",
            "Aerial Topography",
            "LiDAR Solutions",
            "Aerial Photography",
            "Drone Services",
          ],
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
