// src/components/ButtonBox.jsx
import React from "react";

const ButtonBox = ({ onBenefitsClick }) => {
  return (
    <section className="button-box text-center my-8">
      <a
        href="/contact.html"
        className="btn bg-blue-400 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded mr-4"
      >
        Contact us for free demo
      </a>
      <button
        className="btn bg-blue-400 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
        onClick={onBenefitsClick}
      >
        Benefits
      </button>
    </section>
  );
};

export default ButtonBox;
