// src/components/Profile.jsx
import React from 'react';
import pilotImage from '../assets/images/JoshuaHeaton.jpg'; // Adjust the path
import ContactForm from "../components/ContactForm"

const Profile = () => {
  return (
    <section className="py-12">
      <div className="flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-12">
        {/* Profile Image */}
        <div className="w-40 h-40 md:w-48 md:h-48">
          <img
            src={pilotImage}
            alt="Meet Our Drone Pilot"
            className="rounded-full object-cover w-full h-full"
          />
        </div>
        {/* Profile Text */}
        <div className="max-w-md">
          <h3 className="text-2xl font-semibold mb-4">Meet Our Drone Pilot</h3>
          <p className="mb-4">
            Our UAS (drone) service provides planning, implementation, and cloud delivery of data and digital imagery while taking into consideration security, safety, and privacy.
            <br />
            Our team has credentials in:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Licensed FAA part 107 Commercial Remote Pilot</li>
            <li>Unmanned Aerial Systems, Intermediate Technical Certificate</li>
            <li>U.S. Marine Intelligence veteran</li>
            <li>College of Western Idaho, Unmanned Aerial Systems, Advisory Board Member</li>
          </ul>
          <p>
            Whether it is architectural, construction, inspections, real estate or surveys we can capture your data and images.
          </p>
        </div>
      </div>
      {/* Contact Form */}
     
    </section>
  );
};

export default Profile;
