// src/components/CarouselVideoGallery.jsx

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  A11y,
  Autoplay,
  Scrollbar,
  EffectCoverflow,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";

import {
  FaPlayCircle,
  FaTimesCircle,
  FaChevronLeft,
  FaChevronRight,
} from "../icons"; // Ensure this path is correct

const CarouselVideoGallery = ({ videos }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  // Create refs for navigation buttons
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const openModal = (video) => {
    setCurrentVideo(video);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentVideo(null);
    setIsModalOpen(false);
  };

  const showNextVideo = () => {
    if (currentVideo) {
      const currentIndex = videos.findIndex(
        (video) => video.videoId === currentVideo.videoId
      );
      const nextIndex = (currentIndex + 1) % videos.length;
      setCurrentVideo(videos[nextIndex]);
    }
  };

  const showPrevVideo = () => {
    if (currentVideo) {
      const currentIndex = videos.findIndex(
        (video) => video.videoId === currentVideo.videoId
      );
      const prevIndex = (currentIndex - 1 + videos.length) % videos.length;
      setCurrentVideo(videos[prevIndex]);
    }
  };

  // Handle keyboard navigation for accessibility
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isModalOpen) return;

      if (e.key === "Escape") {
        closeModal();
      } else if (e.key === "ArrowRight") {
        showNextVideo();
      } else if (e.key === "ArrowLeft") {
        showPrevVideo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen, currentVideo]);

  return (
    <section id="videoGallery" className="my-16 py-8 bg-white">
      <div className="text-center mb-8">
        <h2 className="text-4xl font-extrabold text-blue-400">Gallery</h2>
        <p className="text-lg text-gray-600 mt-2">
          Explore our collection of stunning aerial footage
        </p>
      </div>
      <div className="max-w-7xl mx-auto px-4">
        <Swiper
          // Register Swiper modules
          modules={[
            Navigation,
            A11y,
            Autoplay,
            Scrollbar,
            EffectCoverflow,
          ]}
          spaceBetween={30}
          slidesPerView={1}
          loop={true} // Enable infinite looping
          autoplay={{
            delay: 3000, // 5 seconds delay between transitions
            disableOnInteraction: false, // Autoplay continues after user interactions
          }}
          speed={1000} // Transition speed in milliseconds (1 second for smoothness)
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onInit={(swiper) => {
            // Assign navigation refs after Swiper initialization
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          effect="coverflow"
          coverflowEffect={{
            rotate: 10,
            stretch: 0,
            depth: 100,
            modifier: 2,
            slideShadows: false,
          }}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
          className="my-8 pb-10" // Added pb-10 for additional bottom padding
        >
          {videos.map((video) => (
            <SwiperSlide
              key={video.videoId}
              className="relative max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
            >
              <div
                className="relative cursor-pointer group"
                onClick={() => openModal(video)}
              >
                <img
                  src={`https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg`}
                  alt={video.alt}
                  className="w-full h-full object-cover rounded-xl shadow-lg transition-transform duration-300 group-hover:scale-105"
                  loading="lazy"
                />
                {/* Overlay */}
                <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-50 rounded-xl"></div>
                {/* Play Button */}
                <FaPlayCircle className="absolute inset-0 m-auto h-16 w-16 text-white opacity-90 transition-transform duration-300 group-hover:scale-110" />
                {/* Video Title */}
                <p className="absolute bottom-4 left-4 right-4 text-white text-center text-lg font-semibold">
                  {video.title}
                </p>
              </div>
            </SwiperSlide>
          ))}
          {/* Navigation Buttons */}
          <div
            ref={prevRef}
            className="swiper-button-prev absolute left-2 top-1/2 transform -translate-y-1/2 z-20 bg-white bg-opacity-75 p-2 rounded-full shadow-md cursor-pointer"
            aria-label="Previous Slide"
          >
            <FaChevronLeft className="text-gray-700" size={20} />
          </div>
          <div
            ref={nextRef}
            className="swiper-button-next absolute right-2 top-1/2 transform -translate-y-1/2 z-20 bg-white bg-opacity-75 p-2 rounded-full shadow-md cursor-pointer"
            aria-label="Next Slide"
          >
            <FaChevronRight className="text-gray-700" size={20} />
          </div>
          {/* Scrollbar */}
          <div className="swiper-scrollbar mt-6"></div>{" "}
          {/* Increased mt-4 to mt-6 */}
        </Swiper>
      </div>

      {/* Modal */}
      {isModalOpen && currentVideo && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm flex items-center justify-center z-50 p-4"
          aria-modal="true"
          role="dialog"
          onClick={closeModal} // Close modal when clicking outside content
        >
          <div
            className="bg-white rounded-2xl overflow-hidden max-w-3xl w-full relative shadow-xl"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal content
          >
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 hover:text-red-500 focus:outline-none z-30 bg-white p-1 rounded-full shadow-md"
              onClick={closeModal}
              aria-label="Close Modal"
            >
              <FaTimesCircle size={36} />
            </button>

            {/* Video Player */}
            <div className="aspect-w-16 aspect-h-9 relative">
              <iframe
                src={`https://www.youtube.com/embed/${currentVideo.videoId}?autoplay=1`}
                title={currentVideo.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </div>

            {/* Navigation Arrows */}
            <button
              className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-90 text-blue-600 p-3 rounded-full focus:outline-none hover:bg-blue-600 hover:text-white transition-colors shadow-md z-20"
              onClick={showPrevVideo}
              aria-label="Previous Video"
            >
              <FaChevronLeft size={24} />
            </button>
            <button
              className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-90 text-blue-600 p-3 rounded-full focus:outline-none hover:bg-blue-600 hover:text-white transition-colors shadow-md z-20"
              onClick={showNextVideo}
              aria-label="Next Video"
            >
              <FaChevronRight size={24} />
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

// Prop type validation
CarouselVideoGallery.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      videoId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CarouselVideoGallery;
