// src/components/BenefitsModal.jsx
import React from "react";
import { FaTimes } from "react-icons/fa";

const BenefitsModal = ({ isOpen, closeModal }) => {
  if (!isOpen) return null;

  const benefits = [
    {
      title: "Unmatched Perspectives",
      description:
        "One of the most significant advantages of aerial drone photography is its ability to capture stunning images from unique vantage points. This bird's-eye view provides clients with a fresh perspective on their property or project, showcasing its true scale and surrounding landscape. Whether it's for real estate listings, construction progress monitoring, or marketing materials, aerial imagery can make a lasting impression on your target audience.",
    },
    {
      title: "Cost-Effective Solutions",
      description:
        "Traditionally, obtaining aerial photographs required hiring a helicopter or airplane, which was both expensive and time-consuming. With drone technology, Osprey Building Performance can provide high-quality commercial aerial photography at a fraction of the cost. This makes aerial imagery more accessible to businesses of all sizes and budgets, allowing them to reap the benefits without breaking the bank.",
    },
    {
      title: "Enhanced Project and Marketing Materials",
      description:
        "Commercial aerial photography offers a wide range of benefits for businesses in Boise, ID and the surrounding areas. From project planning, inspections, job site progressions to captivating efficient data collection and marketing materials, incorporating aerial imagery into your operations can give you a competitive edge. Trust Osprey Building Performance to provide high-quality aerial photography services that will take your business to new heights. Contact us today to learn more.",
    },
    {
      title: "Efficient Data Collection",
      description:
        "For industries such as construction, agriculture, and land management, aerial drone photography can be a valuable tool for collecting essential data. High-resolution images captured by drones can be used to create detailed maps, monitor crop health, assess property damage, and more. This efficient data collection method can save businesses time and resources while providing valuable insights.",
    },
  ];

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={closeModal}
      aria-modal="true"
      role="dialog"
      aria-labelledby="benefitsModalLabel"
    >
      <div
        className="bg-white rounded-lg overflow-auto max-w-4xl w-full relative p-6"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
      >
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-gray-700 hover:text-red-500 focus:outline-none"
          onClick={closeModal}
          aria-label="Close Modal"
        >
          <FaTimes size={24} />
        </button>

        {/* Modal Content */}
        <h2
          id="benefitsModalLabel"
          className="text-2xl font-bold text-blue-500 mb-4 text-center"
        >
          Benefits of Commercial Drone Photography
        </h2>
        <div className="space-y-6">
          {benefits.map((benefit, index) => (
            <div key={index}>
              <h3 className="text-xl font-semibold text-green-600">
                {benefit.title}
              </h3>
              <p className="text-gray-700 mt-2">{benefit.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-6">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default BenefitsModal;
