// src/components/Header.jsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // If using React Router
import logo from "../assets/images/qt=q_95.webp"; // Adjust the path as needed
import { MenuIcon, XIcon } from "@heroicons/react/outline"; // For mobile menu icons

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showTopBar, setShowTopBar] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScroll > lastScrollTop) {
        setShowTopBar(false);
      } else {
        setShowTopBar(true);
      }
      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  return (
    <>
      {/* Top Bar */}
      {showTopBar && (
        <div className="bg-gray-700 text-white text-center py-2 fixed top-0 left-0 right-0 z-50 transition-all duration-300">
          Welcome to OspreyBP! Serving Boise, Meridian, Eagle, Nampa, and
          surrounding areas in Idaho.
        </div>
      )}

      {/* Navbar */}
      <nav
        className={`bg-white shadow-md fixed w-full z-40 ${
          showTopBar ? "mt-10" : "mt-0"
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                <img
                  src={logo}
                  alt="Osprey flying to the left of OspreyBP"
                  className="h-16"
                />
              </Link>
            </div>
            {/* Desktop Menu */}
            <div className="hidden md:flex md:items-center">
              <ul className="flex space-x-6">
                <li>
                  <Link
                    to="/"
                    className="text-lg font-medium text-gray-700 hover:text-blue-600"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/bp"
                    className="text-lg font-medium text-gray-700 hover:text-blue-600"
                  >
                    Building Performance
                  </Link>
                </li>
                <li>
                  <Link
                    to="/drone-services"
                    className="text-lg font-medium text-gray-700 hover:text-blue-600"
                  >
                    Drone Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="text-lg font-medium text-gray-700 hover:text-blue-600"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            {/* Mobile Menu Button */}
            <div className="flex items-center md:hidden">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="text-gray-700 hover:text-blue-600 focus:outline-none focus:text-blue-600"
              >
                {isMobileMenuOpen ? (
                  <XIcon className="h-6 w-6" />
                ) : (
                  <MenuIcon className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden bg-white shadow-md">
            <ul className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <li>
                <Link
                  to="/"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-600"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/bp"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-600"
                >
                  Building Performance
                </Link>
              </li>
              <li>
                <Link
                  to="/Drone-Services"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-600"
                >
                  Drone and LiDAR
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-600"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </>
  );
};

export default Header;
