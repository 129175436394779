// src/components/Deliverables.jsx

import React from "react";
import PropTypes from "prop-types"; // For prop type validation
import { FaCloudUploadAlt, FaFileAlt, FaClipboardCheck } from "../icons"; // Adjust the path as needed
// Centralized icon imports

const Deliverables = ({ deliverables, iconColor = "text-green-600" }) => {
  // If deliverables prop is not provided, use default deliverables
  const defaultDeliverables = [
    {
      icon: FaCloudUploadAlt,
      title: "Data Acquisition",
      description:
        "FAA part 107 certified pilot captures data and digital imagery with drones equipped with photographic, photogrammetric and LiDAR equipment.",
    },
    {
      icon: FaFileAlt,
      title: "Data Processing",
      description:
        "Specialist processes and analyzes data utilizing advanced software based on project deliverables, requirements, and complexities.",
    },
    {
      icon: FaClipboardCheck,
      title: "Data Reporting",
      description:
        "Specialist prepares and delivers reports that can include edited images and videos, PDFs, LAS, DSM, DTM, Contours, Point Cloud, Digital Twins and other file structures.",
    },
  ];

  const deliverablesToRender = deliverables || defaultDeliverables;

  return (
    <section id="deliverables" className="my-8 bg-gray-100 py-8">
      <div className="text-center mb-6">
        <h2 className={`text-3xl font-bold`}>Deliverables</h2>
      </div>
      <div className="flex flex-wrap justify-center gap-8">
        {deliverablesToRender.map((deliverable, index) => {
          const IconComponent = deliverable.icon;
          return (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6 text-center w-full md:w-1/3"
            >
              <IconComponent
                className={`h-12 w-12 ${iconColor} mx-auto mb-4`}
                aria-hidden="true"
              />
              <h3 className={`text-xl font-semibold ${iconColor}`}>
                {deliverable.title}
              </h3>
              <p className="text-gray-700 mt-2">{deliverable.description}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

// Prop type validation
Deliverables.propTypes = {
  deliverables: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType.isRequired, // Icon component type
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  iconColor: PropTypes.string, // Optional prop for icon color
};

// Default props
Deliverables.defaultProps = {
  deliverables: null, // Use default deliverables if not provided
  iconColor: "text-green-600",
};

export default Deliverables;
