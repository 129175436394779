// src/components/ContactForm.jsx
import React, { useState } from "react";

const ContactForm = () => {
  const [companyName, setCompanyName] = useState("");
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [question, setQuestion] = useState("");

  // Place your handleSubmit function here
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { companyName, clientName, email, phoneNumber, question };

    try {
      // Replace 'yourdomain.com' with your actual domain name.
      const response = await fetch("https://ospreybp.com/contact.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded", // URL-encoded data is common for PHP forms
        },
        // Format the data as URL-encoded string
        body: new URLSearchParams(formData).toString(),
      });

      const result = await response.text();
      console.log(result);

      if (response.ok) {
        // Display success message to user
        console.log("Email sent successfully.");
      } else {
        console.error("Error sending email.");
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }

    // Optionally, reset the form fields
    setCompanyName("");
    setClientName("");
    setEmail("");
    setPhoneNumber("");
    setQuestion("");
  };
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Get in Touch</h2>
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
          <div className="mb-4">
            <label
              htmlFor="companyName"
              className="block text-gray-700 font-semibold mb-2"
            >
              Company:
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              placeholder="Your Company"
              className="w-full p-3 border border-gray-300 rounded"
              required
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="clientName"
              className="block text-gray-700 font-semibold mb-2"
            >
              Name:
            </label>
            <input
              type="text"
              id="clientName"
              name="clientName"
              placeholder="Your Name"
              className="w-full p-3 border border-gray-300 rounded"
              required
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 font-semibold mb-2"
            >
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              className="w-full p-3 border border-gray-300 rounded"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="phoneNumber"
              className="block text-gray-700 font-semibold mb-2"
            >
              Phone Number:
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Phone Number"
              className="w-full p-3 border border-gray-300 rounded"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <fieldset>
              <legend className="text-gray-700 font-semibold">
                Your Question:
              </legend>
              <textarea
                id="question"
                name="question"
                placeholder="Please enter your question here"
                className="w-full p-3 border border-gray-300 rounded"
                required
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              ></textarea>
            </fieldset>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-400 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded transition-colors duration-300"
          >
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
