// src/components/Newsletter.jsx
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { MailIcon } from '@heroicons/react/solid/';

const Newsletter = () => {
  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    // Handle newsletter signup (e.g., send to API)
    console.log('Newsletter Signup:', values);
    setSubmitting(false);
    resetForm();
    alert('Thank you for subscribing!');
  };

  return (
    <section className="py-12 bg-blue-300">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-white mb-8">Stay Updated</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="max-w-md mx-auto flex flex-col items-center">
              <div className="flex items-center w-full mb-4">
                <MailIcon className="h-6 w-6 text-white mr-2" />
                <Field
                  type="email"
                  name="email"
                  placeholder="Your Email Address"
                  className="w-full p-3 rounded-l-md focus:outline-none text-gray-800"
                />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-green-500 hover:bg-green-600 text-white p-3 rounded-r-md transition-colors duration-300"
                >
                  Subscribe
                </button>
              </div>
              <ErrorMessage name="email" component="div" className="text-red-300 text-sm mt-1" />
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default Newsletter;
