// src/data/droneVideos.js

const droneVideos = [
  // {
  //   videoId: "NDA0GlqkP8c",
  //   title: "Jobsite Inspection (Sawtooth Gutter Solution)",
  //   alt: "Drone performing aerial surveying over a construction site.",
  // },
  {
    videoId: "YUz4iDHPuIw",
    title: "Photogrammetry Solutions",
    alt: "Drone equipped with LiDAR technology collecting data.",
  },
  {
    videoId: "FSDFLgkKlGc",
    title: "Explore the Green Belt(Drone POV)npm",
    alt: "Drone capturing high-resolution images for photogrammetry.",
  },
  {
    videoId: "OnMEkiiTAwo",
    title: "Real Estate Aerial Footage",
    alt: "Drone providing aerial footage of a residential property.",
  },
  {
    videoId: "OkgDsanauHQ",
    title: "Epic UTV Adventure (Drone POV)",
    alt: "Drone inspecting infrastructure like bridges and power lines.",
  },
  {
    videoId: "LQXzAncTo84",
    title: "Interior Tour",
    alt: "Drone monitoring environmental changes over a forest area.",
  },
];

export default droneVideos;
