// src/pages/Contact.jsx
import React from "react";
import ContactForm from "../components/ContactForm";
import Header from "../components/Header";

const Contact = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-4">Contact Us</h1>
      <ContactForm />
    </div>
  );
};

export default Contact;
