// src/pages/Home.jsx
import React from "react";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";
import Gallery from "../components/Gallery";
import ContactForm from "../components/ContactForm";
import Newsletter from "../components/Newsletter";

const Home = () => {
  return (
    <>
      <SEO
        title="OspreyBP - Boise, Meridian, Eagle & Surrounding Areas"
        description="Osprey Building Performance provides cutting-edge commercial aerial photography and building performance services for Boise, Meridian, Eagle, Nampa, and surrounding areas in Idaho. Contact us to enhance your business!"
        keywords="aerial photography, building performance, Boise, Meridian, Eagle, Nampa, Idaho drone services"
        url="https://www.ospreybp.com/"
        image="https://www.ospreybp.com/assets/images/og-image.jpg" // Replace with your actual image URL
      />
      
      <Hero
        title="Welcome to Osprey"
        subtitle="Comprehensive Solutions for Energy Efficiency and Sustainability"
        backgroundImage="https://i.imgur.com/oXbyZoi.jpg" // Replace with your actual image URL
        ctaText="Get Started"
        ctaLink="/contact"
      />
      <Features />
      <Gallery />
      {/* <Testimonials /> */}
      <ContactForm />
      {/* <Newsletter /> */}
    </>
  );
};

export default Home;
