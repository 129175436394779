// src/components/Features.jsx
import React from "react";
import { TbBuilding , TbDrone } from "react-icons/tb";

import { motion } from "framer-motion";

const features = [
  {
    icon: <TbBuilding className="h-12 w-12  text-green-600" />,
    title: "Building Performance",
    description:
      "Boise's leading Building Performance (BP) experts offer Manual J load calculations for superior HVAC design and energy efficiency in homes and businesses. Get customized, efficient heating, cooling, and ventilation solutions tailored to your needs.",
  },
  {
    icon: <TbDrone className="h-12 w-12 text-blue-500"/>,
    title: "Drone and LiDAR Services",
    description:
      "Boise's premier drone services by our Unmanned Aerial Systems (UAS) division deliver expert aerial photography, site surveys, and data analysis with an unwavering commitment to safety, security, and privacy. Tailored solutions for both commercial and residential needs in Boise.",
  },

  // Add more features as needed
];

const Features = () => {
  return (
    <section className="py-12 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Our Expertise</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 justify-center">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2, duration: 0.6 }}
              whileHover={{ scale: 1.05 }}
            >
              {feature.icon}
              <h3 className="text-xl font-semibold mt-4 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
