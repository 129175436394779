// src/pages/BuildingPerformance.jsx

import React, { useState } from "react";
import SEO from "../components/SEO";
import Hero from "../components/Hero";
import ServicesWeProvide from "../components/ServicesWeProvide";
import SectorsWeServe from "../components/SectorsWeServe";
import Deliverables from "../components/Deliverables";
// import ButtonBox from "../components/ButtonBox";
import ProfileSection from "../components/ProfileSection";
import ContactForm from "../components/ContactForm";
import BenefitsModal from "../components/BenefitsModal";
import Footer from "../components/Footer";
import Background from "../assets/images/hvacHero.jpg"; // Example background image
import {
  FaMapMarkedAlt,
  FaVideo,
  FaCamera,
  FaTimes,
  FaArrowLeft,
  FaArrowRight,
  FaHelicopter,
  FaBolt,
  FaTools,
  FaLeaf,
  FaClipboardCheck,
  FaFileAlt,
  FaCloudUploadAlt,
} from "../icons"; // Adjust the path as needed

const BuildingPerformance = () => {
  // State variables for Benefits Modal
  const [isBenefitsModalOpen, setIsBenefitsModalOpen] = useState(false);

  // State variables for Image Modal
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0);

  // Define services data specific to Building Performance
  const bpServices = [
    {
      title: "Manual JDS",
      description:
        "Manual J, D, and S are key standards for the proper design and installation of residential HVAC equipment and ductwork, ensuring compliance with the International Energy Conservation Code (IECC) and Mechanical Code. Manual J calculates heating and cooling loads for accurate HVAC sizing. Manual D provides guidelines for sizing ductwork for efficient air distribution. Manual S assists in selecting HVAC equipment based on load calculations. Together, these manuals promote energy efficiency, code compliance, and optimal indoor comfort. Osprey BP offers nationwide residential HVAC design services using these standards.",
      icon: FaBolt, // Using FaBolt as a replacement for FaEnergy
    },
    {
      title: "Manual NDS",
      description:
        "Manual N is essential for commercial HVAC load calculations in the HVAC system design process, determining heating and cooling loads for commercial buildings. Manual D sets the industry standard for sizing HVAC return and supply ducts and registers, ensuring proper heating and cooling distribution to each room. Manual S assists in HVAC equipment sizing to meet code requirements. Together, Manuals N, D, and S provide comprehensive HVAC design for comfortable commercial spaces. Osprey BP offers nationwide commercial HVAC design services.",
      icon: FaTools,
    },
    {
      title: "REScheck™",
      description:
        "REScheck and Manual J are both used to measure the building envelope versus the climate zone, but REScheck measures the suitability of the building’s insulation envelope against local climate zone and gives a pass or fail score. Manual J measures the building envelope versus the climate zone and provides sensible heat gain latent heat gain, and total heat loss. REScheck™ ensures your system meets necessary regulations and codes for the town the home is located, reducing the risk of costly fines or penalties. Not all building departments require REScheck.",
      icon: FaLeaf,
    },
    {
      title: "Air Balance",
      description:
        "An air balance measures the performance of an HVAC (Heating, Ventilation, Air Conditioning) system and adjusts to provide the occupants a comfortably conditioned space. It involves adjusting the dampers in the ductwork and fine-tuning the airflow at each diffuser and grille to ensure equal and balanced air flow.",
      icon: FaClipboardCheck,
    },
    {
      title: "Duct Leakage",
      description:
        "Leakage from ducts can account for up to 20% of the energy losses in a building. Duct leakage tests can determine the amount and location the leakage is occurring.​",
      icon: FaLeaf,
    },
  ];

  // Define sectors data specific to Building Performance
  const bpSectors = [
    {
      title: "Architects",
      description:
        "Manual J D S are used in the initial home design process to determine the heating and cooling load (Manual J) so that the proper design and sizing (Manual D) and right HVAC equipment can be considered in the home’s design.",
      icon: FaBolt,
    },
    {
      title: "Builders",
      description:
        "When a home has been designed using the Manual JDS upfront, the builder is able to send a detailed design to the HVAC contractor during the bidding process. The home can be tested and balanced to those specifications removing any ambiguity during the build.",
      icon: FaTools,
    },
    {
      title: "Homeowners",
      description:
        "A well designed, tested, and balanced HVAC system is crucial in creating an energy efficient, comfortable, and healthy indoor environment for the homeowner.",
      icon: FaLeaf,
    },
    {
      title: "HVAC Contractors",
      description:
        "It is more time efficient for HVAC contractors to quote and install when the specifications in the Manual JDS are provided. Once installed, the HVAC can be tested and balanced to those specifications thus meeting code requirements and avoiding rework.",
      icon: FaClipboardCheck,
    },
  ];

  // Define gallery items: Images and Videos
  const galleryItems = [
    {
      type: "image",
      src: "https://i.imgur.com/building-performance-1.jpg",
      alt: "Energy-efficient building interior with modern HVAC systems.",
    },
    // Add more items as needed, e.g., additional images or videos
    // Example of a video item:
    // {
    //   type: "video",
    //   videoId: "dQw4w9WgXcQ", // YouTube video ID
    //   alt: "Drone footage of Boise skyline.",
    // },
  ];

  // Function to open Benefits Modal
  const openBenefitsModal = () => {
    setIsBenefitsModalOpen(true);
  };

  // Function to close Benefits Modal
  const closeBenefitsModal = () => {
    setIsBenefitsModalOpen(false);
  };

  // Function to open Image Modal with specific index
  const openImageModal = (index) => {
    setCurrentGalleryIndex(index);
    setIsImageModalOpen(true);
  };

  // Function to close Image Modal
  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  // Function to show next gallery item
  const showNextGalleryItem = () => {
    setCurrentGalleryIndex(
      (prevIndex) => (prevIndex + 1) % galleryItems.length
    );
  };

  // Function to show previous gallery item
  const showPrevGalleryItem = () => {
    setCurrentGalleryIndex(
      (prevIndex) => (prevIndex - 1 + galleryItems.length) % galleryItems.length
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* SEO Component */}
      <SEO
        title="Building Performance Services | OspreyBP"
        description="Enhance your building's energy efficiency and sustainability with OspreyBP's expert Building Performance services in Boise, Meridian, Eagle, Nampa, and surrounding areas."
        keywords="building performance, energy efficiency, HVAC optimization, sustainability consulting, Boise, Meridian, Eagle, Nampa"
        url="https://www.ospreybp.com/bp"
        image="https://www.ospreybp.com/assets/images/og-building-performance.jpg"
      />

      {/* Hero Section */}
      <Hero
        title="Expert Building Performance Services"
        subtitle="Optimizing Energy Efficiency and Sustainability for Your Building"
        backgroundImage={Background}
        ctaText="Learn More"
        ctaLink="#servicesWeProvide"
        buttonBgColor="bg-green-500" // Dynamic button background color
        buttonHoverBgColor="bg-green-700"
      />

      {/* Services We Provide */}
      <ServicesWeProvide services={bpServices} iconColor="text-green-500" />

      {/* Sectors We Serve */}
      <SectorsWeServe sectors={bpSectors} />

      {/* Profile Section */}
      <ProfileSection />

      {/* Contact Form */}
      <ContactForm />

      {/* Benefits Modal */}
      <BenefitsModal
        isOpen={isBenefitsModalOpen}
        closeModal={closeBenefitsModal}
      />

      {/* Image Modal */}
      {isImageModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          aria-modal="true"
          role="dialog"
        >
          <div className="bg-white rounded-lg overflow-hidden max-w-4xl w-full relative p-6">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-gray-700 hover:text-red-500 focus:outline-none"
              onClick={closeImageModal}
              aria-label="Close Modal"
            >
              <FaTimes size={24} />
            </button>

            {/* Modal Content */}
            <div className="relative">
              {galleryItems[currentGalleryIndex].type === "image" ? (
                <img
                  src={galleryItems[currentGalleryIndex].src}
                  alt={galleryItems[currentGalleryIndex].alt}
                  className="w-full h-auto"
                />
              ) : (
                <div className="responsive-video-container">
                  <iframe
                    src={`https://www.youtube.com/embed/${galleryItems[currentGalleryIndex].videoId}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allowFullScreen
                    className="w-full h-64 md:h-96"
                  ></iframe>
                </div>
              )}

              {/* Navigation Arrows */}
              <button
                className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-r focus:outline-none"
                onClick={showPrevGalleryItem}
                aria-label="Previous"
              >
                <FaArrowLeft />
              </button>
              <button
                className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-l focus:outline-none"
                onClick={showNextGalleryItem}
                aria-label="Next"
              >
                <FaArrowRight />
              </button>
            </div>

            {/* Image Previews */}
            <div className="flex justify-center mt-4 space-x-2">
              {galleryItems.map((item, index) => (
                <img
                  key={index}
                  src={
                    item.type === "image"
                      ? item.src
                      : `https://img.youtube.com/vi/${item.videoId}/0.jpg`
                  }
                  alt={`Preview ${index + 1}`}
                  className={`w-12 h-12 object-cover rounded cursor-pointer ${
                    index === currentGalleryIndex
                      ? "opacity-100 border-2 border-green-600"
                      : "opacity-60"
                  }`}
                  onClick={() => setCurrentGalleryIndex(index)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuildingPerformance;
