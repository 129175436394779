// src/components/ProfileSection.jsx
import React from "react";
import jasonImage from "../assets/images/Jason.jpg";
const ProfileSection = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-12">
        <div className="w-40 h-40 md:w-48 md:h-48">
          <img
            src={jasonImage} // Replace with actual image
            alt="Jason, HVAC Design Specialist"
            className="w-40 h-40 object-cover rounded-full shadow-lg"
          />
        </div>
        <div className="max-w-md">
          <h3 className="text-2xl font-semibold mb-4">
            Meet our HVAC Design Specialist
          </h3>
          <p className="mt-4 text-gray-700">
            Since 2011, Osprey Building Performance has been providing home
            designers, builders, and building owners services to ensure their
            homes are efficient, effective, and healthy homes. Our team has
            credentials in:
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-700">
            <li>HVAC Journeyman</li>
            <li>HVAC Systems Designer</li>
            <li>IDABO Residential Air Balancer</li>
            <li>BPI Building Analyst</li>
          </ul>
          <p className="mt-4 text-gray-700">
            Whether the home is large or small, single-family or multifamily
            dwellings we can advise, design, test, and balance for optimal HVAC
            performance.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ProfileSection;
