// src/App.jsx

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Layout from "./components/Layout";
import HomePage from "./pages/Home";
import BuildingPerformance from "./pages/BuildingPerformance";
import DroneServices from "./pages/DroneServices";
import ContactPage from "./pages/Contact";
// Import other pages as needed

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/bp" element={<BuildingPerformance />} />
          <Route path="/drone-services" element={<DroneServices />} />
          <Route path="/contact" element={<ContactPage />} />
          {/* Add other routes here */}
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
