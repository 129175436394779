// src/components/Gallery.jsx
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Modal from "react-modal";
import { XIcon } from "@heroicons/react/solid/";

Modal.setAppElement("#root"); // For accessibility

const Gallery = () => {
  const photos = [
    {
      src: "https://i.imgur.com/Aov9Rvd.jpg", // Replace with your image URL
      alt: "Aerial view of urban construction site with adjacent roads and residential houses, drone photography.",
    },
    {
      src: "https://i.imgur.com/zdjmkKJ.jpeg", // Replace with your image URL
      alt: "3D photogrammetry image showcasing an industrial facility with storage silos, machinery, and surrounding terrain.",
    },
    {
      src: "https://i.imgur.com/wTcVta0.jpg", // Replace with your image URL
      alt: "LiDAR scan representation of an interior space with bicycles suspended from the ceiling and seating arrangements below.",
    },
    {
      src: "https://i.imgur.com/p1Wakop.png", // Replace with your image URL
      alt: "LiDAR scan representation of an interior space with bicycles suspended from the ceiling and seating arrangements below.",
    },
    // Add more photos as needed
  ];

  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({ src: "", alt: "" });

  const openModal = (image) => {
    setCurrentImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentImage({ src: "", alt: "" });
  };

  return (
    <section className="py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Our Work</h2>
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          loop
          className="w-full h-96 md:h-[600px]"
        >
          {photos.map((photo, index) => (
            <SwiperSlide key={index}>
              <img
                src={photo.src}
                alt={photo.alt}
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                onClick={() => openModal(photo)}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Modal for Image Preview */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Preview"
          className="max-w-4xl mx-auto my-20 bg-white rounded-lg overflow-hidden shadow-lg outline-none"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        >
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
          >
            <XIcon className="h-6 w-6" />
          </button>
          <img
            src={currentImage.src}
            alt={currentImage.alt}
            className="w-full h-auto"
          />
          <p className="p-4 text-center text-gray-700">{currentImage.alt}</p>
        </Modal>
      </div>
    </section>
  );
};

export default Gallery;
