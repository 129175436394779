// src/pages/DroneServices.jsx

import React, { useState } from "react";
import SEO from "../components/SEO";
import Hero from "../components/Hero";
import ServicesWeProvide from "../components/ServicesWeProvide";
import SectorsWeServe from "../components/SectorsWeServe";
import Deliverables from "../components/Deliverables";
import ButtonBox from "../components/ButtonBox";
import ProfileSection from "../components/Profile";
import ContactForm from "../components/ContactForm";
import BenefitsModal from "../components/BenefitsModal";
import Footer from "../components/Footer";
import Background from "../assets/images/boiseriver.png";
import VideoBackground from "../assets/dronehero.mp4"
import CarouselVideoGallery from "../components/CarouselVideoGallery";
import VideoGallery from "../components/VideoGallery";
import droneVideos from "../assets/video/droneVideos";
import {
  FaMapMarkedAlt,
  FaVideo,
  FaCamera,
  FaTimes,
  FaArrowLeft,
  FaArrowRight,
  FaHelicopter,
  FaBolt,
  FaTools,
  FaLeaf,
  FaClipboardCheck,
  FaFileAlt,
  FaCloudUploadAlt,
  FaHome,
  TbDrone,
} from "../icons"; // Adjust the path as needed

const DroneServices = () => {
  // State variables for Benefits Modal
  const [isBenefitsModalOpen, setIsBenefitsModalOpen] = useState(false);

  // State variables for Image Modal
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0);

  // Define services data specific to Drone Services
  const droneServices = [
    {
      title: "Aerial Data Capture",
      description: (
        <>
          <ul className="list-disc ml-5">
            <li>
              Photography in Boise: Elevate your marketing with
              stunning aerial shots of landscapes, real estate listings, and
              ongoing construction projects. Our drone photography captures the
              essence of your property, providing viewers with compelling
              visuals that stand out.
            </li>
            <li>
              Precision Aerial Inspections: Ensure the safety
              and integrity of your project with our thorough aerial
              inspections. Our drones capture data even in the most challenging
              locations, delivering detailed imagery for comprehensive analysis,
              helping you maintain compliance and safety standards.
            </li>
            <li>
              Accessing the Inaccessible: We excel at
              documenting hard-to-reach areas, ensuring every critical detail is
              captured, regardless of location.
            </li>
            <li>
              Job Site Monitoring Made Easy: Keep a close eye
              on your construction projects with our job site monitoring
              services. Regular aerial updates provide a clear overview of
              progress, helping you stay on track and address issues promptly.
            </li>
          </ul>
        </>
      ),
      icon: TbDrone,
    },
    {
      title: "Photogrammetry",
      description: (
        <>
          <li>
            Photogrammetry creates 2D and 3D models by strategically capturing
            images from various angles and digitally reconstructs the physical
            world.
          </li>
          <li>
            This process generates highly detailed and precise representations
            of structures, terrains, and objects, resulting in accurate 3D
            models and orthomosaics that enhance the quality of assessments.
            These models are created with an accurate point cloud which can be
            utilized for measurements, volumetrics, and inspections. This data
            is compatible with GIS and CAD software.
          </li>
        </>
      ),
      icon: FaCamera,
    },
    {
      title: "LiDAR",
      description: (
        <>
      <li>
        LiDAR (Light Detection and Ranging) uses light from a remote sensing laser to collect measurements.
     </li>
     <li>
       These results are used to create 3D models and maps of various environments such as buildings, roads, and other infrastructures.
      </li>
      <li>
        The scanner, attached to a drone or hand-held, can be used both indoors and outdoors to create accurate point clouds. These scans can penetrate vegetation and access the ground data which then can be used to build digital terrain (DTM), digital elevation models (DEMs) and generate contours of landscapes.
       </li>
     </>
       ),
        icon: FaVideo,
    },
  ];

  // Define sectors data specific to Drone Services
  const droneSectors = [
    {
      title: "Architects",
      description:
        "Photogrammetry, LiDAR scanning and aerial photography assist architects and engineers in the design process. These services provide highly accurate and detailed data captures of buildings and sites as they currently exist.",
      icon: FaMapMarkedAlt,
    },
    {
      title: "Construction Managers",
      description:
        "Photogrammetry, LiDAR scanning and aerial photography has improved the way construction professionals plan and build structures. Common uses are for site progression and stockpile management by providing highly accurate visual output and detailed measurement that captures buildings and sites.",
      icon: FaCamera,
    },
    {
      title: "Inspectors",
      description:
        "Photogrammetry and LiDAR (drone or mobile) assist inspectors by faster data collection and processing, more accurate measurements, and increased safety for employees. These high-resolution images lead to a more comprehensive understanding of infrastructure condition and performance, enabling proactive maintenance and repair strategies.",
      icon: FaVideo,
    },
    {
      title: "Realtors",
      description:
        "Aerial photos and videos inside home or building and outside. Captures the entirety of the property from various views and relative to the nearby surroundings. This gives the potential buyer additional information to make an informed decision.",
      icon: FaHome,
    },
    {
      title: "Surveyors",
      description:
        "Unmanned Aerial Systems (UAS) / drone operators assist land surveyors in acquiring high precision data from the air in a fraction of the time and cost. The results are 3D Point Clouds that enable the surveyor to evaluate landscape conditions, assess vegetation and accurately measure terrain and existing assets.",
      icon: FaHelicopter,
    },
  ];

  // Define gallery items: Images and Videos
  const galleryItems = [
    {
      type: "image",
      src: "https://i.imgur.com/Aov9Rvd.jpg",
      alt: "Aerial view of urban construction site with adjacent roads and residential houses, drone photography.",
    },
    // Add more items as needed, e.g., additional images or videos
    // Example of a video item:
    // {
    //   type: "video",
    //   videoId: "dQw4w9WgXcQ", // YouTube video ID
    //   alt: "Drone footage of Boise skyline.",
    // },
  ];

  // Function to open Benefits Modal
  const openBenefitsModal = () => {
    setIsBenefitsModalOpen(true);
  };

  // Function to close Benefits Modal
  const closeBenefitsModal = () => {
    setIsBenefitsModalOpen(false);
  };

  // Function to open Image Modal with specific index
  const openImageModal = (index) => {
    setCurrentGalleryIndex(index);
    setIsImageModalOpen(true);
  };

  // Function to close Image Modal
  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  // Function to show next gallery item
  const showNextGalleryItem = () => {
    setCurrentGalleryIndex(
      (prevIndex) => (prevIndex + 1) % galleryItems.length
    );
  };

  // Function to show previous gallery item
  const showPrevGalleryItem = () => {
    setCurrentGalleryIndex(
      (prevIndex) => (prevIndex - 1 + galleryItems.length) % galleryItems.length
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* SEO Component */}
      <SEO
        title="Drone Service Provider in Boise | OspreyBP | Aerial Imagery Experts"
        description="Explore top-tier drone services in Boise with OspreyBP, your premier Drone Service Provider. Specializing in high-resolution aerial imagery for construction, real estate, and surveying. Book your professional drone shoot today."
        keywords="Drone Service Provider, Boise drone photography, aerial imagery, professional drone services, high-resolution drone photos, construction drone photography, real estate aerial photos, surveying drone imagery, OspreyBP"
        url="https://www.ospreybp.com/drone-services"
        image="https://www.ospreybp.com/assets/images/og-drone-photography.jpg"
      />

      {/* Hero Section */}
      <Hero
        title="Drone Service Provider in Boise"
        subtitle="Photography, Photogrammetry, and LiDAR Solutions"
        videoSrc={VideoBackground} // Use your mp4 video here
        ctaText="Learn More"
        ctaLink="#servicesWeProvide"
        buttonBgColor="bg-blue-300"
      />
      <CarouselVideoGallery videos={droneVideos} />

      {/* Services We Provide */}
      <ServicesWeProvide services={droneServices} iconColor="text-blue-400" />

      {/* Sectors We Serve */}
      <SectorsWeServe sectors={droneSectors} iconColor="text-blue-400" />

      {/* Deliverables */}
      <Deliverables iconColor="text-blue-400" />

      {/* Button Box */}
      <ButtonBox onBenefitsClick={openBenefitsModal} />

      {/* Profile Section */}
      <ProfileSection />

      {/* Contact Form */}
      <ContactForm />

      {/* Benefits Modal */}
      <BenefitsModal
        isOpen={isBenefitsModalOpen}
        closeModal={closeBenefitsModal}
      />

      {/* Image Modal */}
      {isImageModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          aria-modal="true"
          role="dialog"
        >
          <div className="bg-white rounded-lg overflow-hidden max-w-4xl w-full relative p-6">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-gray-700 hover:text-red-500 focus:outline-none"
              onClick={closeImageModal}
              aria-label="Close Modal"
            >
              <FaTimes size={24} />
            </button>

            {/* Modal Content */}
            <div className="relative">
              {galleryItems[currentGalleryIndex].type === "image" ? (
                <img
                  src={galleryItems[currentGalleryIndex].src}
                  alt={galleryItems[currentGalleryIndex].alt}
                  className="w-full h-auto"
                />
              ) : (
                <div className="responsive-video-container">
                  <iframe
                    src={`https://www.youtube.com/embed/${galleryItems[currentGalleryIndex].videoId}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allowFullScreen
                    className="w-full h-64 md:h-96"
                  ></iframe>
                </div>
              )}

              {/* Navigation Arrows */}
              <button
                className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-r focus:outline-none"
                onClick={showPrevGalleryItem}
                aria-label="Previous"
              >
                <FaArrowLeft />
              </button>
              <button
                className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-l focus:outline-none"
                onClick={showNextGalleryItem}
                aria-label="Next"
              >
                <FaArrowRight />
              </button>
            </div>

            {/* Image Previews */}
            <div className="flex justify-center mt-4 space-x-2">
              {galleryItems.map((item, index) => (
                <img
                  key={index}
                  src={
                    item.type === "image"
                      ? item.src
                      : `https://img.youtube.com/vi/${item.videoId}/0.jpg`
                  }
                  alt={`Preview ${index + 1}`}
                  className={`w-12 h-12 object-cover rounded cursor-pointer ${
                    index === currentGalleryIndex
                      ? "opacity-100 border-2 border-green-600"
                      : "opacity-60"
                  }`}
                  onClick={() => setCurrentGalleryIndex(index)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DroneServices;
