// src/components/Hero.jsx
import React from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

const Hero = ({
  title,
  subtitle,
  backgroundImage,
  videoSrc,
  ctaText,
  ctaLink,
  buttonBgColor = "bg-blue-400",
  buttonHoverBgColor = "bg-blue-700",
}) => {
  return (
    <section className="relative h-screen">
      {videoSrc ? (
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={videoSrc}
          autoPlay
          loop
          muted
          playsInline
        />
      ) : (
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url('${backgroundImage}')` }}
        />
      )}
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 flex items-center justify-center h-full">
        <motion.div
          className="text-center text-white px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-4xl md:text-6xl font-bold mb-4">{title}</h1>
          <p className="text-xl md:text-2xl mb-8">{subtitle}</p>
          {/* Uncomment below if you want to display a CTA button */}
          {/* <Link
            to={ctaLink}
            className={`inline-flex items-center ${buttonBgColor} ${buttonHoverBgColor} text-white font-semibold py-3 px-6 rounded transition duration-300`}
          >
            {ctaText}
            <ChevronRightIcon className="h-5 w-5 ml-2" />
          </Link> */}
        </motion.div>
      </div>
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  videoSrc: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
  buttonBgColor: PropTypes.string,
  buttonHoverBgColor: PropTypes.string,
};

Hero.defaultProps = {
  buttonBgColor: "bg-blue-400",
  buttonHoverBgColor: "bg-blue-700",
};

export default Hero;
