// src/components/ServicesWeProvide.jsx

import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  A11y,
  Autoplay,
  Scrollbar,
  Pagination,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/pagination";

const ServicesWeProvide = ({ services, iconColor = "text-blue-600" }) => {
  return (
    <section id="servicesWeProvide" className="py-12 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">
          Services We Provide
        </h2>
        <Swiper
          modules={[Navigation, A11y, Autoplay, Scrollbar, Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          loop={false}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          speed={1200}
          navigation={true}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          // Remove autoHeight
          // autoHeight={true} // Removed this line
          breakpoints={{
            640: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          className="my-8 pb-10"
        >
          {services.map((service, index) => {
            const IconComponent = service.icon;
            return (
              <SwiperSlide key={index} className="flex h-auto items-stretch">
                <div className="flex flex-col items-center justify-between bg-white rounded-lg shadow-lg p-6 hover:shadow-2xl transition-transform duration-300 w-full">
                  {/* Icon */}
                  <div>
                    <IconComponent
                      className={`h-16 w-16 ${iconColor} transition-colors duration-300`}
                      aria-hidden="true"
                    />
                  </div>
                  {/* Content */}
                  <div className="mt-4 text-center flex-grow flex flex-col">
                    <h3 className="text-xl font-semibold">{service.title}</h3>
                    <p className="text-gray-600 mt-2 flex-grow">
                      {service.description}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          {/* Scrollbar */}
        </Swiper>
      </div>
    </section>
  );
};

// Prop type validation
ServicesWeProvide.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    })
  ).isRequired,
  iconColor: PropTypes.string,
};

export default ServicesWeProvide;
